@import 'https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700';

.sidebar {
  border-right: 0.5px solid lightgray;
  flex: 1;
  min-height: 100vh;
  background-color: white;
}
.top {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 11px;
}
.top img {
  width: 108px;
}
.logo {
  font-size: 20px;
  font-weight: bold;
  /* color: blueviolet; */
}
hr {
  height: 0;
  border: 0.5px solid lightgray;
}
/* .center{
    padding-left: 10px;
} */
.center ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.title {
  font-size: 10px;
  font-weight: bold;
  color: #999;
  margin-top: 15px;
  margin-bottom: 5px;
}
.center li {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}
.center li:hover {
  background-color: #ece8ff;
  border-left: 4px solid blue;
  margin-left: 5px;
}
li .active {
  background-color: #ece8ff;
  height: 43px;
  width: -webkit-fill-available;
  padding-top: 6px;
  margin-right: -5px;
  border-left: 4px solid blue;
}

.center span {
  font-size: 17px;
  font-weight: 600;
}

/* test css */
/*
    DEMO STYLE
*/

body {
  font-family: 'Poppins', sans-serif;
  background: #fafafa;
}

/* p {
  font-family: 'Poppins', sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
} */

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.navbar {
  /* padding: 15px 10px; */
  /* background: #fff; */
  border: none;
  border-radius: 0;
  /* margin-bottom: 40px; */
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.wrapper {
  display: flex;
  align-items: stretch;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  /* background: #7386D5;
    color: #fff; */
  transition: all 0.3s;
}

#sidebar.active {
  margin-left: -250px;
}

#sidebar .sidebar-header {
  padding: 20px;
  /* background: #cfd1d6; */
}

#sidebar ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #eef1f3;
}

#sidebar ul p {
  /* color: #fff; */
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}
/* #sidebar ul li a:hover {
    color: #7386D5;
    background: #fff;
}

#sidebar ul li.active > a, a[aria-expanded="true"] {
    color: #fff;
    background: #6d7fcc;
} */

a[data-toggle='collapse'] {
  position: relative;
}

a[aria-expanded='false']::before,
a[aria-expanded='true']::before {
  content: '\e259';
  display: block;
  position: absolute;
  right: 20px;
  font-family: 'Glyphicons Halflings';
  font-size: 0.6em;
}
a[aria-expanded='true']::before {
  content: '\e260';
}

/* ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}

ul.CTAs {
    padding: 20px;
} */

/* ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
} */

/* a.article, a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
} */

#sidebarCollapse {
  display: none;
}

@media only screen and (max-width: 764px) {
  #sidebarCollapse {
    display: block;
    background-color: #1976d2;
    width: 80px;
    height: 50px;
  }
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 768px) {
  #sidebar {
    margin-left: -250px;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
}
