.modal {
  width: 620px;
  min-height: 400px;
  padding: 20px;
  padding-top: 5px;
  border-radius: 8px;
  background-color: #fff;
  box-sizing: 0px 3px 8px rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 15;
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  animation: popup 0.2s ease-out;
}

.closeBtn {
  margin-left: auto;
  width: 30px;
  height: 30px;
  padding: 0;
  color: #000;
  background: transparent;
  border-radius: 4px;
  transition: all 0.3s;
}
.closeBtn:focus {
  outline: none;
}
.closeBtn:hover {
  background: #ebebeb;
}

.modal input:disabled {
  background-color: #f1f1f1;
  border-color: #f1f1f1;
  cursor: not-allowed;
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

.imageContainer {
  width: 200px;
  height: 150px;
  border: 1px solid #7e7e7e;
  border-radius: 8px;
  position: relative;
  padding: 15px;
  overflow: hidden;
}
.selectedImage {
  padding: 0px;
}

.imageContainer img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: all 0.3s;
}

.imageContainer input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.imageContainer:has(input:disabled) img {
  filter: contrast(0);
}

.removeImageBtn {
  position: absolute;
  padding: 0;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -7px;
  right: 3px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  outline: none;
  transition: all 0.3s;
}
.removeImageBtn:focus {
  outline: none;
}
.removeImageBtn:active {
  transform: scale(0.9);
}

.modal input {
  padding: 8px;
  border: 1px solid #a5a5a5;
  border-radius: 4px;
}

.descriptionTextarea {
  padding: 8px;
  border: 1px solid #a5a5a5;
  border-radius: 4px;
  resize: none;
}

.submitBtn:focus {
  outline: none;
}

@keyframes popup {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}
