.section {
  background: #fff;
  padding: 0 20px;
  padding-bottom: 50px;
  /* width: 100%; */
}

.innerContainer {
  /* background: #fafafa; */
  padding: 20px;
}

.listContainer {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  row-gap: 50px;
}

.itemImgContainer {
  width: 100%;
  /* height: 300px; */
  aspect-ratio: 1.6/1;

  position: relative;
}

.innerContainer .item img,
.innerContainer .item video {
  width: 100%;
  height: 100%;
  /* max-height: 300px; */
  aspect-ratio: 1.6/1;
}
.innerContainer .item video {
  object-fit: cover;
}

.editBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  fill: #000;
  color: #000;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  transition: all 0.2s;
  margin: 0;
  z-index: 10;
}

.deleteBtn {
  right: 60px;
}

.editBtn:focus {
  outline: none;
}
.editBtn:active {
  transform: scale(0.97);
}

.itemInfo {
  margin-top: 10px;
  padding-right: 20px;
}

.itemInfo h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}

.itemInfo p {
  font-size: 14px;
}

.deleteModal,
.overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  padding: 30px 20px;
  background-color: #fff;
  border-radius: 5px;
  z-index: 20;
}

.deleteModal p {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 15;
}

.deleteModal button:focus {
  outline: none;
}

.confirmDeleteBtn {
  margin-left: 10px !important;
  background-color: rgb(206, 2, 2) !important;
}
.confirmDeleteBtn:hover {
  background-color: rgb(192, 0, 0) !important;
}
