.new{
    width: 100%;
    display: flex;
}
.newContainer{
    flex: 6;
}
.topnew,.bottomnew,.imagenew{
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0.47);
    box-shadow: 2px 4px 10px 1px rgba(201,201,201.47);
    padding: 10px;
    margin: 20px;
    display: flex;
}
.topnew h1{
    color: lightgray;
    font-size: 20px;
}
.leftnew{
    flex: 1;
    text-align: center;
}
.leftnew img{
    width: 225px;
    height: 166px;
    
    object-fit: cover;

}
.rightnew{
    flex: 2;
}
form{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-around
}
.formInput{
    width: 30%;
}
.formInputs{
    width: 40%;
}
.formInput input ,.formInputs input{
    width: 100%;
    padding: 5px;
    border: none;
    border-bottom: 1px solid gray;
}
.formInput label, .formInputs label{
    display: flex;
    align-items: center;
    gap: 10px;
}
.formInput .icon{
    cursor: pointer;
}
button{
    width: 150px;
    padding: 10px;
    border: none;
    background-color: teal;
    color: white;
    font-weight: bold;
    cursor: pointer ;
    margin-top:10px ;
}    


.style{
white-space: nowrap;
}