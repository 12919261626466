.style {
  white-space: nowrap;
  position: 'top-right';
}
.blog-form form {
  flex-direction: column;
  gap: 0;
  max-width: 300px;
  width: 300px;
  margin-bottom: 20px;
}
.blog-form .addBlog-img {
  width: 300px;
  height: 250px;
}
.blog-form .addBlog-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1.3px solid gray;
  border-radius: 10px;
}
.blog-form .addArticle_label {
  margin: 10px 0;
}
.blog-form input[type='text'] {
  width: 100%;
  height: 30px;
  border-radius: 5px;
}
