.cases{
    width: 100%;
    display: flex;
}
.newContainer{
    flex: 6;
}
.addArticle_label {
    font-weight: 600;
  }
  .addArticle_heading {
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 1.3rem;
  }