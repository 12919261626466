.cases {
  width: 100%;
  display: flex;
}
.newContainer {
  flex: 6;
}
.addArticle_label {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
}
.editIcon__container {
  display: flex;
  flex-direction: row;
}
.editIcon__icon {
  height: 20px;
  width: 20px;
  margin-top: 5px;
  margin-left: 4px;
  cursor: pointer;
}
.addArticle_heading {
  font-weight: bold;
  margin-bottom: 30px;
  font-size: 1.3rem;
}
