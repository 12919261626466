@media all and (min-width: 480px) {
    .Login {
        padding: 30px 0;
    }

    .Login form {
        margin: 0 auto;
        max-width: 320px;
    }
}

.Login {
    margin: 0 5%;
}

.bottom-navi-custom {
    position: absolute;
    width: 100%;
    z-index: 9999999;
    bottom: -100px;
    max-width: 80vw;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
}


.bottom-navi-button {
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    margin: 0 5px;
    border: 1px solid black;
    padding: 5px 10px;
    border-radius: 5px;
    transition: 0.3s ease all;
}

.bottom-navi-button.selected {
    background: #61dafb;
}

#react-paginate ul {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
}




.previous,
.next {
    margin: 0 15px;
    font-weight: 700;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: rgba(97, 218, 251, 0.94);

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

}
.login__logo {
    margin-top: 83px;
    margin-bottom: 20px;
    object-fit: contain;
    width: 100px;
    margin-right: auto;
    margin-left: auto;
  }
  .login__container {
      width: 364px;
      height: 347px;
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      border: 1px solid lightgray;
      padding: 20px;
    margin-right: auto;
        margin-left: auto;
        background-color: white;
  }

  .login__container>h2 {
      font-weight: 500;
      margin-bottom: 20px;
    background-color: white;
  }

.navi-li {
    font-weight: 700;
    margin: 0 10px;
    font-size: 16px;
    transition: 0.3s ease all;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navi-li.active {
    background: rgba(97, 218, 251, 0.45);
}